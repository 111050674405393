import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../../features/auth/authSlice';
import { useLoginCommanderMutation } from '../../../features/commander/commanderApiSlice';
import usePersist from '../../../hooks/usePersist';
import PulseLoader from 'react-spinners/PulseLoader';
import { Box, Button, FormControl, InputLabel, Input, Paper, Typography, Checkbox, FormControlLabel, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import { usfuelblack } from '../../../assets';

const CommanderLogin = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const userRef = useRef();
    const errRef = useRef();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [persist, setPersist] = usePersist();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loginCommander, { isLoading }] = useLoginCommanderMutation();

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [username, password]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const result = await loginCommander({ username, password }).unwrap();
        
            // Log the response to verify the structure
            console.log("Login Response:", result);
        
            // Handle new password required
            if (result.message === 'NEW_PASSWORD_REQUIRED') {
                navigate('/commander-reset-password', { 
                    state: { session: result.session, username: result.username } 
                });
                return;
            }
        
            // Normal login flow
            if (result.accessToken) {
                const { accessToken } = result;
                dispatch(setCredentials({ commanderToken: accessToken })); // Update to store commanderToken
                setUsername('');
                setPassword('');
                navigate('/commander-console'); // Redirect to Commander Console after successful login
            } else {
                setErrMsg('No access token received');
            }
        } catch (err) {
            console.log("Error logging in: ", err)
            if (!err.status) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg(err.data?.message || 'Login failed');
            }
            errRef.current.focus();
        }
    };
    
    

    const handleUserInput = (e) => setUsername(e.target.value);
    const handlePwdInput = (e) => setPassword(e.target.value);
    const handleToggle = () => setPersist((prev) => !prev);

    if (isLoading) return <PulseLoader color={'#000'} />;

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: colors.grey[600],
            }}
        >
            <Paper
                elevation={6}
                sx={{
                    p: 6,
                    width: '100%',
                    maxWidth: '400px',
                    textAlign: 'center',
                    backgroundColor: colors.grey[200],
                    borderRadius: '20px',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <img src={usfuelblack} alt="Logo" style={{ width: '200px' }} />
                </Box>
                <Typography
                    variant="h2"
                    color={colors.blueAccent[700]}
                    gutterBottom
                    sx={{ fontFamily: theme.typography.fontFamily }}
                >
                    Commander Login
                </Typography>
                <Box component="form" onSubmit={handleSubmit}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel htmlFor="username" sx={{ fontSize: '18px', color: colors.primary[600] }}>
                            Username
                        </InputLabel>
                        <Input
                            id="username"
                            type="text"
                            value={username}
                            onChange={handleUserInput}
                            autoComplete="off"
                            required
                            inputRef={userRef}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ mb: 3 }}>
                        <InputLabel htmlFor="password" sx={{ fontSize: '18px', color: colors.primary[600] }}>
                            Password
                        </InputLabel>
                        <Input
                            id="password"
                            type="password"
                            value={password}
                            onChange={handlePwdInput}
                            required
                        />
                    </FormControl>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <FormControlLabel
                            control={<Checkbox checked={persist} onChange={handleToggle} color="primary" />}
                            label="Trust This Device"
                        />
                    </Box>
                    <Typography variant="body2" color="error" ref={errRef} sx={{ mb: 2 }}>
                        {errMsg}
                    </Typography>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2, fontFamily: 'Jura, sans-serif', fontSize: '16px' }}
                    >
                        Sign In
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default CommanderLogin;
