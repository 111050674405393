import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: { token: null, commanderToken: null }, // Add commanderToken for commander-specific auth
    reducers: {
        setCredentials: (state, action) => {
            const { accessToken, commanderToken } = action.payload;
            if (accessToken) {
                state.token = accessToken;
            }
            if (commanderToken) {
                state.commanderToken = commanderToken;
            }
        },
        logOut: (state) => {
            state.token = null;
            state.commanderToken = null; // Log out of both
        },
    }
});

export const { setCredentials, logOut } = authSlice.actions;
export default authSlice.reducer;
export const selectCurrentToken = (state) => state.auth.token;
export const selectCommanderToken = (state) => state.auth.commanderToken;
