import { Box, Button, Typography, useTheme, Tab, Tabs } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import { tokens } from "../../../../theme";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../global/Header";
import { useGetOrganizationByIdQuery } from "../../../../features/organizations/organizationsApiSlice";
import { useGetUsersByOrganizationIdQuery } from "../../../../features/users/usersApiSlice";
import {
    AdminPanelSettingsOutlined,
    LockOutlined,
    SecurityOutlined
} from "@mui/icons-material";
import PulseLoader from "react-spinners/PulseLoader";
import { useGetAtgsByOrgIdQuery } from "../../../../features/atgs/atgsApiSlice";
import { useEffect, useState } from "react";
import addYears from 'date-fns/addYears';
import useAuth from "../../../../hooks/useAuth";

const OrgUsers = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const params = useParams();
    const organization_id = params.organization_id;

    const { username, status, organization_id: user_organization_id } = useAuth();
    const isManagerOutsideOrg = status === "Manager" && user_organization_id !== organization_id;

    const { data: org_query } = useGetOrganizationByIdQuery(organization_id);
    const organization = org_query?.entities?.[organization_id];

    const { data: users, isSuccess, isLoading, error } = useGetUsersByOrganizationIdQuery(organization_id);
    const { data: atgs, isAtgsLoading, isAtgsError } = useGetAtgsByOrgIdQuery(organization_id);

    const user_columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            cellClassName: "name-column--cell",
        },
        {
            field: "organization_id",
            headerName: "Organization ID",
            headerAlign: "left",
            align: "left",
        },
        {
            field: "organization_name",
            headerName: "Organization Name",
            headerAlign: "left",
            align: "left",
            flex: 1,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
        },
        {
            field: "role",
            headerName: "Access Level",
            flex: 1,
            renderCell: ({ row: { role } }) => (
                <Box
                    width="60%"
                    m="0 auto"
                    p="5px"
                    display="flex"
                    justifyContent="center"
                    backgroundColor={
                        role === "Admin"
                            ? colors.greenAccent[600]
                            : role === "Manager"
                                ? colors.greenAccent[700]
                                : colors.greenAccent[700]
                    }
                    borderRadius="4px"
                >
                    {role === "Admin" && <AdminPanelSettingsOutlined />}
                    {role === "Manager" && <LockOutlined />}
                    {role === "User" && <SecurityOutlined />}
                    <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                        {role}
                    </Typography>
                </Box>
            ),
        },
        // Only add Actions column if user is not a Manager accessing an external organization
        ...(isManagerOutsideOrg ? [] : [{
            field: "username",
            headerName: "Actions",
            cellClassName: "name-column--cell",
            flex: 1,
            renderCell: (params) => (
                <Box display="flex" justifyContent="space-between">
                    <Link to={`/console/users/${params.value}`} color="secondary">
                        <Button variant="contained">View</Button>
                    </Link>
                    <Link to={`/console/users/${params.value}/edit`} color="secondary">
                        <Button variant="contained">Edit</Button>
                    </Link>
                </Box>
            ),
        }])
    ];

    const hasSubscription = organization && organization.subscription;

    // Calculate default end date (today + 1 year)
    const today = new Date();
    const defaultSubscriptionEndDate = addYears(today, 1);
    const defaultTotalSeats = hasSubscription ? organization.subscription.userSeats : 1;

    const [serverTotalSeats, setServerTotalSeats] = useState(defaultTotalSeats);

    const userList = isSuccess ? Object.values(users) : [];
    const totalUserLimit = atgs?.length * 4;
    const currentUserCount = userList.length || 0;
    const isUserLimitReached = currentUserCount >= serverTotalSeats;

    // Update server-side seat count when organization data changes
    useEffect(() => {
        if (hasSubscription) {
            setServerTotalSeats(organization.subscription.userSeats);
        }
    }, [organization, hasSubscription]);

    if (isLoading) {
        return <PulseLoader color={"#FFF"} />;
    }

    const shouldShowDataGrid = !error || error?.data?.message === "No users found for this organization";

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="end" mt="20px">
                {!isManagerOutsideOrg && (
                    isUserLimitReached ? (
                        <Button
                            variant="outlined"
                            sx={{
                                color: "red",
                                borderColor: "red",
                                "&:hover": {
                                    borderColor: "darkred",
                                    backgroundColor: "transparent"
                                }
                            }}
                        >
                            Out of Seats!
                        </Button>
                    ) : (
                        <Link to={`/console/organization/${organization_id}/users/new`} color="secondary">
                            <Button variant="contained">Register New User</Button>
                        </Link>
                    )
                )}
            </Box>
            <Header title="TEAM" subtitle={`Managing the Team Members - ${currentUserCount}/${serverTotalSeats}`} />
            <Box
                m="40px 0 0 0"
                height="75vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .name-column--cell": {
                        color: colors.greenAccent[300],
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.blueAccent[700],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                    },
                    "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.blueAccent[700],
                    },
                    "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                    },
                }}
            >
                {shouldShowDataGrid ? (
                    <DataGrid
                        rows={userList}
                        columns={user_columns}
                        getRowId={(row) => row._id}
                    />
                ) : (
                    <Typography variant="h6" color="error">
                        {error?.data?.message}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default OrgUsers;
