import React, { useState } from 'react';
import { Button, Grid, FormControlLabel, Checkbox, Typography } from "@mui/material";
import { useClearAlarmsMutation, useClearAllAlarmsMutation } from '../../../../../features/atgs/atgsApiSlice';

const ClearAlarms = ({ atg, atg_name }) => {
    const [selectedTank, setSelectedTank] = useState(null);
    const [clearAll, setClearAll] = useState(false);

    const [clearAlarms] = useClearAlarmsMutation();
    const [clearAllAlarms] = useClearAllAlarmsMutation();

    const handleTankSelection = (event) => {
        const { name, checked } = event.target;
        if (checked) {
            setSelectedTank(name);
            setClearAll(false); // Deselect clear all if a specific tank is selected
        } else {
            setSelectedTank(null);
        }
    };

    const handleClearAllChange = (event) => {
        setClearAll(event.target.checked);
        if (event.target.checked) {
            setSelectedTank(null); // Clear specific selection if clearing all
        }
    };

    const handleSubmit = () => {
        if (clearAll) {
            clearAllAlarms({ atg_name });
        } else if (selectedTank) {
            clearAlarms({ atg_name, tankNumber: selectedTank.replace('t', '') });
        }
    };

    return (
        <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12}>
                <Typography variant="h6">Select Tank to Clear Alarms</Typography>
                {Object.keys(atg).filter(key => key.startsWith('t')).map(tank => (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedTank === tank}
                                onChange={handleTankSelection}
                                name={tank}
                                disabled={clearAll}
                            />
                        }
                        label={`Tank ${tank.replace('t', '')} (${atg[tank].product})`}
                        key={tank}
                    />
                ))}
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={clearAll}
                            onChange={handleClearAllChange}
                            name="clearAll"
                        />
                    }
                    label="Clear Alarms on All Tanks"
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '10px' }}
                    onClick={handleSubmit}
                >
                    Clear Alarms
                </Button>
            </Grid>
        </Grid>
    );
};

export default ClearAlarms;
