import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../../theme";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { useGetAuthorizedAtgsQuery } from "../../../../features/atgs/atgsApiSlice";
import PulseLoader from 'react-spinners/PulseLoader';
import { useGetOrganizationByIdQuery } from "../../../../features/organizations/organizationsApiSlice";

const ATGWidget = () => {
    const { username, role, organization_id, authorizedATGs } = useAuth();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const colors = tokens(theme.palette.mode);

    const {
        data: org_query,
        isLoading: isOrgLoading  // destructuring isLoading from the hook’s response
    } = useGetOrganizationByIdQuery(organization_id);
    const organization = org_query?.entities?.[organization_id];

    const organization_type = organization?.type || 'Admin'

    console.log("Sending authorized query: ", role, organization_id, organization_type)
    // Use the new `getAuthorizedAtgsQuery` hook
    // Use `getAuthorizedAtgsQuery` only after `organization_type` is available
    const {
        data: atgs,
        isLoading: isLoading,
        isSuccess: isSuccess,
        isError: isError,
        error: error,
    } = useGetAuthorizedAtgsQuery(
        { role, organization_id, organization_type },
        { skip: isOrgLoading || !organization_type }  // Skip until org query is done and org type is available
    );

    console.log("Found ATGs: ", error);


    const columns = [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            renderCell: (params) => (
                <Link to={`/console/atgs/${params.row.thingName}`}>
                    <Typography variant="body1" color={colors.greenAccent[400]}>
                        {params.value}
                    </Typography>
                </Link>
            ),
        },
        {
            field: "address",
            headerName: "Address",
            flex: 1,
            hide: isMobile,
            renderCell: (params) => (
                <Typography variant="body1" color={colors.greenAccent[400]}>
                    {params.value}
                </Typography>
            ),
        },
        {
            field: "organization_id",
            headerName: "Organization",
            headerAlign: "left",
            align: "left",
            flex: 1,
            hide: isMobile,
        },
    ];

    let content;

    if (isLoading) {
        content = <PulseLoader color={"#FFF"} />;
    } else if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>;
    } else if (isSuccess) {
        const atgRows = atgs.map(atg => ({
            ...atg,
            name: atg.attributes.name.replace(/_/g, ' '),
            address: atg.attributes.address.replace(/_/g, ' '),
        }));

        content = (
            <Box width="100%" height="50vh" m="20px" sx={{
                "& .MuiDataGrid-root": { border: "none" },
                "& .MuiDataGrid-cell": { borderBottom: "none" },
                "& .MuiDataGrid-columnHeaders": { backgroundColor: colors.blueAccent[700], borderBottom: "none" },
                "& .MuiDataGrid-virtualScroller": { backgroundColor: colors.primary[400] },
                "& .MuiDataGrid-footerContainer": { borderTop: "none", backgroundColor: colors.blueAccent[700] },
                "& .MuiCheckbox-root": { color: `${colors.greenAccent[200]} !important` },
            }}>
                <DataGrid
                    rows={atgRows}
                    columns={columns}
                    onRowClick={(params) => navigate(`/console/atgs/${params.row.thingName}`)}
                />
            </Box>
        );
    }

    return content;
};

export default ATGWidget;
