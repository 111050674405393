import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';
import { tokens } from '../../../theme';

console.log("Client Node ENV: ", process.env.NODE_ENV);

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box>
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{
          mb: "5px",
          wordWrap: 'break-word',
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem', lg: '3rem' } // Responsive font sizes
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h5"
        color={colors.greenAccent[400]}
        sx={{
          wordWrap: 'break-word',
          fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } // Responsive font sizes
        }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
}

export default Header;
