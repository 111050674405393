import { apiSlice } from "../../app/api/apiSlice";

export const commanderApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        loginCommander: builder.mutation({
            query: (credentials) => ({
                url: '/commander-auth',
                method: 'POST',
                body: { ...credentials },
            }),
        }),
        resetCommanderPassword: builder.mutation({
            query: (resetData) => ({
                url: '/commander-auth/reset-password',
                method: 'POST',
                body: { ...resetData },
            }),
        }),
        getDownloadUrl: builder.mutation({
            query: () => ({
                url: '/commander-auth/download',
                method: 'GET',
            }),
        }),
    }),
});

export const { useLoginCommanderMutation, useResetCommanderPasswordMutation, useGetDownloadUrlMutation } = commanderApiSlice;
