import { jsPDF } from "jspdf";
import "jspdf-autotable";

const allValuesZero = (tankData) => {
    return tankData.volume === 0 && tankData.ullage === 0 && tankData.temperature === 0 && tankData.height === 0;
};

const formatDate = (date) => `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

const generatePDF = (data, range, atgAttributes, logoImg, config) => {
    const doc = new jsPDF();

    // Initialize total dispensed based on active tanks
    const totalDispensed = Object.fromEntries(
        Object.keys(config.tank_config)
            .filter((tankKey) => config.tank_config[tankKey].config_flag_raw === "1")
            .map((tankKey) => [tankKey, 0])
    );

    // Calculate total dispensed only for active tanks
    data.forEach((report, index) => {
        const tanks = report?.state?.reported?.tanks || {};
        Object.keys(tanks).forEach((tankKey) => {
            const tankData = tanks[tankKey]?.tank_data?.[0];
            const prevTankData = index > 0 ? data[index - 1]?.state?.reported?.tanks[tankKey]?.tank_data?.[0] : null;
            if (tankData && prevTankData && totalDispensed.hasOwnProperty(tankKey)) {
                const lost = Number(prevTankData.volume || 0) - Number(tankData.volume || 0);
                totalDispensed[tankKey] += lost < 0 ? 0 : lost;
            }
        });
    });

    doc.setFont('helvetica');

    const generateTankTable = (startY, tankData, tankLabel, summary, isFirstTable = false) => {
        const tableHeight = (tankData.length + 1) * 8;
        const spaceForTitleAndHeader = 20;

        if (isFirstTable) {
            if (startY + tableHeight + spaceForTitleAndHeader > 280) {
                startY = 80;
            }
        } else {
            if (startY + tableHeight + spaceForTitleAndHeader > 280) {
                doc.addPage();
                startY = 10;
            }
        }

        doc.text(`Data for ${tankLabel}`, 10, startY);
        doc.setFontSize(10);
        doc.text(`Summary: ${summary}`, 10, startY + 8);
        doc.setFontSize(12);

        doc.autoTable({
            startY: startY + 18,
            head: [['Date', 'Volume (Gal.)', 'Ullage (Gal.)', 'Temperature (°F)', 'Height (in.)']],
            body: tankData.filter(row => row[0]) // Filter out rows where the date is null, blank, or an empty string
        });

        return startY + tableHeight + 20;
    };

    let tableStartY = 80;

    if (logoImg) {
        const aspectRatio = 306 / 1266;
        doc.addImage(logoImg, 'PNG', 10, 25, 50, 50 * aspectRatio);
    }

    doc.setFontSize(14);
    const atgName = atgAttributes?.name?.replace(/_/g, ' ');
    const atgAddress = atgAttributes?.address?.replace(/_/g, ' ');

    doc.text(`ATG UST Location: ${atgName}`, 70, 25);
    doc.text(`Address: ${atgAddress}`, 70, 32);
    doc.setFontSize(12);
    let dispensedTextYPosition = 70;

    // Display total dispensed only for active tanks
    Object.keys(totalDispensed).forEach((tankKey) => {
        const tankLabel = config.tank_config[tankKey]?.product_label || tankKey.toUpperCase();
        if (totalDispensed[tankKey] !== 0) {
            doc.text(`Total Dispensed (${tankLabel}): ${totalDispensed[tankKey].toLocaleString()} Gal.`, 70, dispensedTextYPosition);
            dispensedTextYPosition += 5;
        }
    });

    doc.setFontSize(10);
    const reportDate = new Date();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    doc.text(`Date: ${reportDate.toLocaleDateString('en-US')}`, 70, 45);
    doc.text(`Time: ${reportDate.toLocaleTimeString('en-US')} (${timeZone})`, 70, 50);
    doc.text(`Website: usfuelpro.com`, 70, 55);
    doc.text(`Phone: 512-923-8799`, 70, 60);
    doc.setFontSize(12);

    const title = `Report For The Last ${range.charAt(0).toUpperCase() + range.slice(1)}`;
    doc.text(title, 10, tableStartY - 10);
    doc.line(10, tableStartY - 8, 10 + title.length * 2, tableStartY - 8);

    const currentTime = new Date();

    const activeTanks = Object.keys(config.tank_config).filter(tankKey => config.tank_config[tankKey].config_flag_raw === "1");

    activeTanks.forEach((tankKey) => {
        const tankConfig = config.tank_config[tankKey];
        const tankLabel = tankConfig.product_label || `Tank ${tankConfig.tank_number}`;
        const summary = `Full Volume: ${tankConfig.full_height_volume} gal, Diameter: ${tankConfig.tank_diameter} in, Product: ${tankConfig.product_label || "N/A"}`;

        let tankData = [];
        const filteredData = data.filter(d => {
            const tank = d.state.reported.tanks[tankKey]?.tank_data?.[0];
            return tank && !allValuesZero(tank);
        });

        // Use only the latest point per day for weekly and monthly reports
        if (range === "week" || range === "month") {
            const groupedData = {};

            filteredData.forEach((d) => {
                const recordDate = new Date(`${d.state.reported.system_date} ${d.state.reported.system_time}`);
                const formattedDate = formatDate(recordDate);
                const tank = d.state.reported.tanks[tankKey]?.tank_data?.[0];
                if (!groupedData[formattedDate] || new Date(groupedData[formattedDate].timestamp) < recordDate) {
                    groupedData[formattedDate] = tank;
                }
            });

            Object.values(groupedData).forEach((tank) => {
                if (tank) {
                    tankData.push([
                        tank.timestamp,
                        tank.volume?.toLocaleString() || "N/A",
                        tank.ullage?.toLocaleString() || "N/A",
                        tank.temperature !== null && tank.temperature !== undefined ? tank.temperature.toFixed(2) : "N/A",
                        tank.height !== null && tank.height !== undefined ? tank.height.toFixed(2) : "N/A"
                    ]);
                }
            });
        } else {
            filteredData.forEach((d) => {
                const tank = d.state.reported.tanks[tankKey]?.tank_data?.[0];
                if (tank) {
                    tankData.push([
                        tank.timestamp,
                        tank.volume?.toLocaleString() || "N/A",
                        tank.ullage?.toLocaleString() || "N/A",
                        tank.temperature !== null && tank.temperature !== undefined ? tank.temperature.toFixed(2) : "N/A",
                        tank.height !== null && tank.height !== undefined ? tank.height.toFixed(2) : "N/A"
                    ]);
                }
            });
        }

        if (tankData.length > 0) {
            tableStartY = generateTankTable(tableStartY, tankData, tankLabel, summary, tableStartY === 80);
        }
    });

    const formattedTime = currentTime.toISOString().replace(/[^0-9]/g, '');
    const filename = `usfuel_report_${range}_${formattedTime}.pdf`;
    doc.save(filename);
};

export default generatePDF;
