import React from 'react';
import { Typography, Paper, Card, CardContent, List, ListItem, ListItemText, Chip, Box, useTheme } from "@mui/material";
import { useGetATGAlarmDataQuery } from '../../../../../features/atgs/atgsApiSlice';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from 'moment';
import { tokens } from '../../../../../theme';

const AlarmTab = ({ atg_name }) => {
    const { data, error, isLoading } = useGetATGAlarmDataQuery(atg_name);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const alarms = data?.shadow?.alarms || [];

    if (isLoading) return <Typography>Loading alarms...</Typography>;
    if (error) return <Typography>Error loading alarms: {error.message}</Typography>;

    return (
        <Paper elevation={2} style={{ padding: '20px', marginTop: '20px' }}>
            <Typography variant="h5" style={{ marginBottom: '20px' }}>
                Alarms
            </Typography>
            <Card elevation={3}>
                <CardContent style={{ height: 'calc(2.5em * 10)', overflowY: 'scroll' }}>
                    <List>
                        {alarms.length > 0 ? (
                            alarms.map((alarm, index) => {
                                const alarmTime = alarm.alarm_time ? moment(alarm.alarm_time) : null;
                                const timeAgo = alarmTime ? moment().diff(alarmTime, 'minutes') : null;

                                return (
                                    <ListItem key={index}>
                                        <Paper
                                            elevation={2}
                                            style={{
                                                padding: '15px',
                                                borderRadius: '10px',
                                                background: `linear-gradient(135deg, ${colors.primary[400]}, ${colors.primary[600]})`,
                                                width: '100%',
                                                color: theme.palette.getContrastText(colors.primary[400])
                                            }}
                                        >
                                            <Typography variant="h6" gutterBottom>
                                                Alarm Details
                                            </Typography>
                                            <ListItemText
                                                primary={
                                                    <>
                                                        <Typography variant="body1"><strong>Category:</strong> {alarm.category || 'N/A'}</Typography>
                                                        <Typography variant="body1"><strong>Category Description:</strong> {alarm.category_description || 'N/A'}</Typography>
                                                        <Typography variant="body1"><strong>Sensor Category:</strong> {alarm.sensor_category || 'N/A'}</Typography>
                                                        <Typography variant="body1"><strong>Alarm Type:</strong> {alarm.alarm_type || 'N/A'}</Typography>
                                                        <Typography variant="body1"><strong>Alarm Description:</strong> {alarm.alarm_description || 'N/A'}</Typography>
                                                        <Typography variant="body1"><strong>Tank Sensor Number:</strong> {alarm.tank_sensor_number || 'N/A'}</Typography>
                                                        {alarmTime && (
                                                            <Box display="flex" alignItems="center" mt={1}>
                                                                <AccessTimeIcon fontSize="small" style={{ marginRight: '5px' }} />
                                                                <Typography variant="body2">
                                                                    {`${timeAgo} minutes ago`}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </>
                                                }
                                            />
                                        </Paper>
                                    </ListItem>
                                );
                            })
                        ) : (
                            <Typography variant="body1">No alarms found.</Typography>
                        )}
                    </List>
                </CardContent>
            </Card>
        </Paper>
    );
}

export default AlarmTab;
