import React, { useState, useEffect } from 'react';
import {
    Typography,
    Grid,
    Paper,
    Switch,
    TextField,
    Box,
    Button,
    useTheme,
} from "@mui/material";
import { CheckCircle, Cancel, WaterSharp } from '@mui/icons-material';
import { tokens } from '../../../../../theme';
import { useStartStopCSLDTestMutation, useGetATGLeakDataQuery, useSetATGJobScheduleMutation, useGetCSLDScheduleQuery } from '../../../../../features/atgs/atgsApiSlice';

const USFuelCSLDControls = ({ atg_name }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [duration, setDuration] = useState(6);
    const [startTime, setStartTime] = useState('00:00');
    const [showScheduleSection, setShowScheduleSection] = useState(false);
    const [csldEnabled, setCsldEnabled] = useState(false);
    const [runningTanks, setRunningTanks] = useState(0);
    const [selectedTankInfo, setSelectedTankInfo] = useState({});

    const [startStopCSLDTest, { isLoading: isToggleLoading, isError: isToggleError }] = useStartStopCSLDTestMutation();
    const [setATGJobSchedule, { isLoading: isScheduleLoading, isError: isScheduleError }] = useSetATGJobScheduleMutation();
    const { data: leakData, isLoading: isLeakLoading } = useGetATGLeakDataQuery(atg_name, {});
    const { data: scheduleData, isLoading: isScheduleLoadingQuery } = useGetCSLDScheduleQuery(atg_name);

    // Load existing schedule on mount
    useEffect(() => {
        if (scheduleData?.scheduleExists) {
            const cronExpression = scheduleData.scheduleDetails.cronExpression;
            const duration = scheduleData.scheduleDetails.duration;

            // Convert cron expression to time and set duration
            const [minute, hour] = cronExpression.match(/\d+/g).slice(0, 2);
            setStartTime(`${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`);
            setDuration(duration);
            setShowScheduleSection(true);
            //console.log("Schedule found and set: ", cronExpression, duration)
        } else {
            setShowScheduleSection(false);
        }
    }, [scheduleData]);

    useEffect(() => {
        if (leakData) {
            const tanks = leakData?.shadow?.tanks || {};
            let activeTanks = 0;
            let firstRunningTank = null;
            const currentTime = new Date();

            Object.values(tanks).forEach(tank => {
                const csldResult = tank?.usfuel_csld_result || {};
                if (csldResult.status === "Running") {
                    const resultTimestamp = new Date(csldResult.timestamp);
                    const timeDiff = (currentTime - resultTimestamp) / (1000 * 60);
                    if (timeDiff <= 2) {
                        activeTanks += 1;
                        if (!firstRunningTank) {
                            firstRunningTank = csldResult;
                        }
                    }
                }
            });

            setRunningTanks(activeTanks);
            setCsldEnabled(activeTanks > 0);
            setSelectedTankInfo(firstRunningTank || {});
        }
    }, [leakData]);

    const handleCsldToggle = async () => {
        const action = csldEnabled ? "stop" : "start";
        setCsldEnabled(!csldEnabled);

        try {
            await startStopCSLDTest({ atg_name, action }).unwrap();
            console.log(`CSLD test ${action} triggered successfully.`);
        } catch (error) {
            console.error("Failed to toggle CSLD test:", error);
            setCsldEnabled(csldEnabled);
        }
    };

    const generateCronExpression = (startTime) => {
        const [hours, minutes] = startTime.split(":");
        return `cron(${minutes} ${hours} * * ? *)`; 
    };

    const handleSaveSettings = async () => {
        if (duration < 6 || duration > 24) {
            alert("Please set a duration between 6 and 24 hours.");
            return;
        }

        const cronExpression = generateCronExpression(startTime);

        try {
            await setATGJobSchedule({
                atg_name,
                jobType: "startCSLDTest",
                action: "start",
                cronExpression,
                duration
            }).unwrap();
            console.log(`CSLD test scheduled successfully with cron: ${cronExpression}`);
        } catch (error) {
            console.error("Failed to schedule CSLD test:", error);
        }
    };

    return (
        <Paper
            elevation={3}
            style={{
                padding: '20px',
                background: `linear-gradient(to bottom, ${colors.primary[400]}, ${colors.primary[600]})`
            }}
        >
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box display="flex" alignItems="center" gap={1}>
                    <WaterSharp style={{ color: colors.greenAccent[300] }} />
                    <Typography variant="h4" style={{ color: colors.greenAccent[300] }}>
                        USFuel CSLD Controls
                    </Typography>
                </Box>

                <Paper elevation={1} style={{
                    padding: '10px 15px',
                    background: colors.primary[700],
                    minWidth: '200px',
                }}>
                    <Box display="flex" alignItems="center" gap={1}>
                        {csldEnabled ?
                            <CheckCircle style={{ color: 'green' }} /> :
                            <Cancel style={{ color: 'red' }} />}
                        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            CSLD Status
                        </Typography>
                    </Box>
                    <Typography variant="body2">Status: {csldEnabled ? "Running" : "Stopped"}</Typography>
                    <Typography variant="body2">Tanks Running: {runningTanks}</Typography>
                    <Typography variant="body2">
                        Elapsed Time: {selectedTankInfo.current_test_time_elapsed || "N/A"}
                    </Typography>
                    <Typography variant="body2">
                        Last Run: {selectedTankInfo.timestamp || "N/A"}
                    </Typography>
                </Paper>
            </Box>

            <Box mb={3}>
                <Typography variant="h6" gutterBottom>
                    Manual Control
                </Typography>
                <Typography variant="body1">CSLD On/Off</Typography>
                <Switch
                    checked={csldEnabled}
                    onChange={handleCsldToggle}
                    color="primary"
                    disabled={isToggleLoading || isLeakLoading}
                    sx={{
                        transform: 'scale(1.5)',
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                            backgroundColor: 'green',
                        },
                        '& .MuiSwitch-switchBase': {
                            color: colors.primary[200],
                        },
                        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                            backgroundColor: 'red',
                        },
                    }}
                />
                {isToggleError && <Typography color="error">Failed to toggle CSLD.</Typography>}
            </Box>

            {showScheduleSection && (
                <Box mb={3}>
                    <Typography variant="h6" gutterBottom>
                        Scheduling
                    </Typography>
                    <Typography variant="body2" style={{ color: colors.primary[200], marginBottom: '10px' }}>
                        Configure the test to run daily at the specified start time for the set duration (between 6 and 24 hours).
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Duration (hrs)"
                                type="number"
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Start Time"
                                type="time"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </Box>
            )}

            <Box display="flex" justifyContent="center">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveSettings}
                    style={{ marginTop: '10px' }}
                    disabled={isScheduleLoading}
                >
                    Save Settings
                </Button>
                {isScheduleError && <Typography color="error">Failed to save schedule.</Typography>}
            </Box>
        </Paper>
    );
};

export default USFuelCSLDControls;
