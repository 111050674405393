import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import { Link, useNavigate } from "react-router-dom";
import {
  useGetOrganizationsQuery,
  useDeleteOrganizationMutation,
} from "../../../features/organizations/organizationsApiSlice";
import useAuth from "../../../hooks/useAuth";
import PulseLoader from "react-spinners/PulseLoader";
import Header from "../global/Header";

const Organizations = () => {
  const { role, isAdmin, organization_id } = useAuth();
  const [open, setOpen] = useState(false);
  const [orgToDelete, setOrgToDelete] = useState(null);

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Fetch organizations only once organization_id is available

  
  const {
    data: organizations,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetOrganizationsQuery(
    { role, organization_id },
    {
      skip: !organization_id, // Skip until organization_id is defined
      pollingInterval: 15000,
      refetchOnFocus: true,
      refetchOnMountOrArgChange: true,
    }
  );

  const [deleteOrganization] = useDeleteOrganizationMutation();

  const handleRowClick = (param) => {
    navigate(`/console/organization/${param.row.organization_id}`);
  };

  const handleDelete = (id) => {
    setOrgToDelete(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (orgToDelete) {
      await deleteOrganization(orgToDelete);
    }
    setOpen(false);
  };

  const columns = [
    {
      field: "organization_id",
      headerName: "ID",
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Typography variant="h7" color={colors.greenAccent[400]}>
          {params.value}
        </Typography>
      ),
    },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "address", headerName: "Address", flex: 1 },
    { field: "phone", headerName: "Phone Number", flex: 1 },
    { field: "email", headerName: "Primary Email", flex: 1 },
    { field: "type", headerName: "Organization Type", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params) => (
        <>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              navigate(`/console/organization/${params.id}/edit`);
            }}
          >
            Edit
          </Button>
          <Button
            style={{ backgroundColor: "red", color: "white" }}
            variant="contained"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              handleDelete(params.row.organization_id);
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  let content;

  if (isLoading || !organization_id) {
    content = <PulseLoader color={"#FFF"} />;
  } else if (isError) {
    content = <p className="errmsg">{error?.data?.message}</p>;
  } else if (isSuccess) {
    const orgRows = Object.values(organizations.entities).map((org) => ({
      ...org,
    }));

    content = (
      <Box m="20px">
        <Box display="flex" justifyContent="end" mt="20px">
          <Link to="/console/organizations/new" color="secondary" variant="contained">
            Create New Organization
          </Link>
        </Box>
        <Header title="Organizations" subtitle="Managing Organizations" />
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { borderBottom: "none" },
            "& .name-column--cell": { color: colors.greenAccent[300] },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
          }}
        >
          <DataGrid
            rows={orgRows}
            columns={columns}
            onRowClick={handleRowClick}
          />
        </Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this organization?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} style={{ color: "green" }}>
              Cancel
            </Button>
            <Button onClick={handleConfirmDelete} style={{ color: "red" }} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  return content;
};

export default Organizations;
