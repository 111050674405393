import React, { useState } from 'react';
import {
    Typography,
    useTheme,
    Grid,
    Paper,
    Box,
    List,
    ListItem,
    ListItemText,
} from "@mui/material";
import { tokens } from '../../../../../theme';

import ChangeHeaders from '../forms/ChangeHeaders';
import ClearAlarms from '../forms/ClearAlarms';
import USFuelCSLDControls from '../forms/USFuelCSLDControls';
import useAuth from '../../../../../hooks/useAuth';

const JobsDispatch = ({ atg, atg_name }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { role } = useAuth();
    const sidebarItems = role === 'Admin' ? ['Change Headers', 'Clear Alarms', 'USFuel CSLD'] : ['Change Headers', 'USFuel CSLD'];
    const [selectedItem, setSelectedItem] = useState(0);

    const getSettingsComponent = () => {
        switch (selectedItem) {
            case 0:
                return <ChangeHeaders atg={atg} atg_name={atg_name} />;
            case 1:
                return role === 'Admin' ? <ClearAlarms atg={atg} atg_name={atg_name} /> : <USFuelCSLDControls atg={atg} atg_name={atg_name}/>;
            case 2:
                return <USFuelCSLDControls atg={atg} atg_name={atg_name} />;
            default:
                return null;
        }
    };

    return (
        <Grid container spacing={3} style={{ height: '100%' }}>
            {/* Left Sidebar */}
            <Grid item xs={12} md={3} style={{ height: '100%' }}>
                <Paper
                    elevation={2}
                    style={{
                        height: '100%',
                        padding: '20px',
                        background: `linear-gradient(to bottom, ${colors.grey[800]}, ${colors.blueAccent[900]})`,
                        overflowY: 'auto',
                    }}
                >
                    <List>
                        {sidebarItems.map((item, index) => (
                            <ListItem
                                button
                                selected={selectedItem === index}
                                onClick={() => setSelectedItem(index)}
                                key={index}
                                style={{
                                    color: selectedItem === index ? colors.greenAccent[200] : colors.primary[100],
                                    fontWeight: selectedItem === index ? 'bold' : 'normal',
                                    padding: '10px 0',
                                }}
                            >
                                <ListItemText primary={item} />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Grid>

            {/* Main Content Section */}
            <Grid item xs={12} md={9} style={{ height: '100%' }}>
                <Paper
                    elevation={2}
                    style={{
                        height: '60vh',  // Set fixed height for consistency
                        padding: '20px',
                        background: `linear-gradient(to bottom, ${colors.primary[400]}, ${colors.primary[600]})`,
                        overflowY: 'auto',  // Make the main content scrollable
                    }}
                >
                    <Typography
                        variant="h4"
                        style={{ marginBottom: '10px', color: colors.greenAccent[300] }}
                    >
                        {sidebarItems[selectedItem]}
                    </Typography>
                    <Box style={{ maxHeight: 'calc(100% - 40px)', overflowY: 'auto' }}>
                        {getSettingsComponent()}
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default JobsDispatch;
