import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken, selectCommanderToken } from "./authSlice"; // Import both selectors

const RequireAuth = ({ isCommander = false }) => {
    const token = useSelector(selectCurrentToken);
    const commanderToken = useSelector(selectCommanderToken); // Add commanderToken
    const location = useLocation();

    // If isCommander is true, check for commanderToken, else check for the regular token
    const isAuthenticated = isCommander ? commanderToken : token;

    return (
        isAuthenticated
            ? <Outlet />
            : <Navigate to={isCommander ? "/commander" : "/login"} state={{ from: location }} replace />
    );
};

export default RequireAuth;
