import React, { useState, useEffect } from 'react';
import { Paper, Typography, Box, CircularProgress } from "@mui/material";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useGetRecentAtgQuery, useGetAtgReportsQuery, useGetAtgQuery, useGetATGConfigDataQuery } from '../../../../features/atgs/atgsApiSlice';
import useAuth from '../../../../hooks/useAuth';
import { Link } from 'react-router-dom';
import { useGetOrganizationByIdQuery } from '../../../../features/organizations/organizationsApiSlice';

const transformDataForChart = (reports) => {
    return reports.map((report) => {
        const { system_date, system_time, tanks } = report.state.reported;
        return {
            time: `${system_date} ${system_time}`,
            volumeT1: tanks.tank_1?.tank_data[0]?.volume || 0,
            volumeT2: tanks.tank_2?.tank_data[0]?.volume || 0,
            volumeT3: tanks.tank_3?.tank_data[0]?.volume || 0,
            volumeT4: tanks.tank_4?.tank_data[0]?.volume || 0,
        };
    });
};

const ChartWidget = () => {
    const { username, role, organization_id } = useAuth();
    const [range, setRange] = useState('day');


    const {
        data: org_query,
        isLoading: isOrgLoading  // destructuring isLoading from the hook’s response
    } = useGetOrganizationByIdQuery(organization_id);
    const organization = org_query?.entities?.[organization_id];

    const organization_type = organization?.type

    // Fetch the most recent ATG with authorization parameters
    const { data: recentAtg, isLoading: isRecentLoading } = useGetRecentAtgQuery({
        role,
        organization_id,
        organization_type
    });
    const atg_name = recentAtg?.atg_name;

    // Fetch the ATG details and attributes only when atg_name is available
    const {
        data: atgReturn,
        isLoading: isAtgLoading,
        isError: isAtgError,
        error: Atgerror,
    } = useGetAtgQuery(atg_name, {
        skip: !atg_name,  // Skip query until atg_name is defined
    });

    const atgAttributes = atgReturn?.attributes;
    const chartTitle = atgAttributes?.name.replace(/_/g, ' ') || atg_name || 'Loading...';

    // Fetch configuration data only when atg_name is available
    const { data: configData, isLoading: isConfigLoading } = useGetATGConfigDataQuery(atg_name, {
        skip: !atg_name,  // Skip query until atg_name is defined
    });
    const tankConfig = configData?.shadow.tank_config || {};


    // Fetch reports for the recent ATG
    const {
        data: reports,
        isLoading: isReportsLoading,
        refetch,
    } = useGetAtgReportsQuery({ atg_name, range }, {
        skip: !atg_name,
    });

    useEffect(() => {
        if (atg_name) {
            console.log('Refetching reports for ATG: ', atg_name);
            refetch();
        }
    }, [atg_name, refetch]);

    const [data, setData] = useState([]);

    useEffect(() => {
        if (reports) {
            const sortedReports = [...reports].sort((a, b) => {
                const dateA = new Date(`${a.state.reported.system_date}T${a.state.reported.system_time}`);
                const dateB = new Date(`${b.state.reported.system_date}T${b.state.reported.system_time}`);
                return dateA - dateB;
            });

            // Filter to show every 10th data point
            const filteredReports = sortedReports.filter((_, index) => index % 3 === 0);

            // Transform data for the chart
            const transformedData = transformDataForChart(filteredReports);
            setData(transformedData);
        }
    }, [reports]);

    // Create product labels for legend and volumes display
    const productLabels = {
        tank_1: tankConfig.tank_1?.product_label || 'Tank 1',
        tank_2: tankConfig.tank_2?.product_label || 'Tank 2',
        tank_3: tankConfig.tank_3?.product_label || 'Tank 3',
        tank_4: tankConfig.tank_4?.product_label || 'Tank 4',
    };

    const currentVolumes = {
        volumeT1: atgReturn?.shadow?.t1?.volume || 0,
        volumeT2: atgReturn?.shadow?.t2?.volume || 0,
        volumeT3: atgReturn?.shadow?.t3?.volume || 0,
        volumeT4: atgReturn?.shadow?.t4?.volume || 0,
    };

    // Filter tanks that have non-zero volume at least once
    const nonZeroTanks = Object.keys(currentVolumes).filter((key) => {
        return data.some((entry) => entry[key] > 0);
    });

    // Show a loading indicator while ATG data is being fetched
    if (isAtgLoading || isConfigLoading || isRecentLoading) {
        return (
            <Paper sx={{ p: 4, minHeight: '33%', borderRadius: '8px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </Paper>
        );
    }

    // Handle error if ATG data fails to load
    if (isAtgError) {
        return (
            <Paper sx={{ p: 4, minHeight: '33%', borderRadius: '8px' }}>
                <Typography variant="h6" color="error">
                    Failed to load ATG data: {Atgerror.message}
                </Typography>
            </Paper>
        );
    }

    return (
        <Paper sx={{ p: 4, minHeight: '33%', borderRadius: '8px' }}>
            <Typography variant="h4" gutterBottom sx={{ alignSelf: 'flex-start' }}>
                <Link to={`/console/atgs/${atg_name}`}>{`${chartTitle} Daily Volume Report`}</Link>
            </Typography>
            <Box sx={{ height: 400 }}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={400}
                        height={250}
                        data={data}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="time" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        {nonZeroTanks.includes('volumeT1') && (
                            <Line type="monotone" dataKey="volumeT1" stroke="#32CD32" name={productLabels.tank_1} />
                        )}
                        {nonZeroTanks.includes('volumeT2') && (
                            <Line type="monotone" dataKey="volumeT2" stroke="#82ca9d" name={productLabels.tank_2} />
                        )}
                        {nonZeroTanks.includes('volumeT3') && (
                            <Line type="monotone" dataKey="volumeT3" stroke="#8884d8" name={productLabels.tank_3} />
                        )}
                        {nonZeroTanks.includes('volumeT4') && (
                            <Line type="monotone" dataKey="volumeT4" stroke="#FF4500" name={productLabels.tank_4} />
                        )}
                    </LineChart>
                </ResponsiveContainer>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
                {Object.entries(currentVolumes).map(([key, volume]) => (
                    volume > 0 && (
                        <Typography key={key} variant="h6">
                            {productLabels[key.replace('volumeT', 'tank_')]}: {volume} gallons
                        </Typography>
                    )
                ))}
            </Box>
        </Paper>
    );
};

export default ChartWidget;
