import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Typography, Tabs, Tab } from "@mui/material";
import { useGetAtgReportsQuery } from '../../../../../features/atgs/atgsApiSlice';
import generatePDF from '../components/PdfGeneration';
import ChartTab from '../components/ChartTab';
import DataGridTab from '../components/DataGridTab';
import TankAnalytics from '../components/TankAnalytics';

const columns = [
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'volume', headerName: 'Volume (Gal.)', width: 130 },
    { field: 'ullage', headerName: 'Ullage (Gal.)', width: 130 },
    { field: 'temperature', headerName: 'Temperature (°F)', width: 150 },
    { field: 'height', headerName: 'Height (in.)', width: 130 },
];

const InventoryHistory = ({ atg_name, atgAttributes, logoImg, config }) => {
    
    const [selectedTanks, setSelectedTanks] = useState(() => {
        const initialSelected = {};
        Object.keys(config.tank_config).forEach(tankKey => {
            const isDisabled = config.tank_config[tankKey].config_flag_raw === '0';
            initialSelected[tankKey] = !isDisabled;
        });
        return initialSelected;
    });
    


    const [selectedTab, setSelectedTab] = useState(0);
    const [range, setRange] = useState("week");
    const [data, setData] = useState([]);
    const [sortedReports, setSortedReports] = useState(null); // Track sorted reports state
    const [dataForGrids, setDataForGrids] = useState({});

    const handleTabChange = (event, newValue) => setSelectedTab(newValue);

    const {
        data: reports,
        isError,
        isLoading,
        refetch
    } = useGetAtgReportsQuery({ atg_name, range });

    useEffect(() => {
        console.log("Unsorted Reports:", reports)
        if (reports && !isLoading) {
            const sortedReportsData = [...reports].sort((a, b) => {
                const dateTimeA = new Date(`${a.state.reported.system_date}T${a.state.reported.system_time}`).getTime();
                const dateTimeB = new Date(`${b.state.reported.system_date}T${b.state.reported.system_time}`).getTime();
                
                return dateTimeA - dateTimeB;
            });
            
            setSortedReports(sortedReportsData);
            setData(sortedReportsData);

            const grids = {};

            console.log("Sorted Reports:", sortedReportsData);

            sortedReportsData.forEach((report) => {
                const date = `${report.state.reported.system_date} ${report.state.reported.system_time}`;
                const tanks = report.state.reported.tanks;

                Object.keys(tanks).forEach((tankKey) => {
                    const tankData = tanks[tankKey]?.tank_data?.[0];
                    if (tankData && !allValuesZero(tankData)) {
                        if (!grids[tankKey]) grids[tankKey] = [];
                        grids[tankKey].push({
                            id: `${date}-${tankKey}`,
                            date,
                            volume: tankData.volume,
                            ullage: tankData.ullage,
                            temperature: tankData.temperature,
                            height: tankData.height,
                        });
                    }
                });
            });

            setDataForGrids(grids);
        }
    }, [reports, isLoading, range]);

    useEffect(() => {
        const refetchData = async () => {
            await refetch();
        };
        refetchData();
    }, [range, refetch]);

    const allValuesZero = (tankData) => {
        return tankData.volume === 0 && tankData.ullage === 0 && tankData.temperature === 0 && tankData.height === 0;
    };

    const handleTankCheck = (tank) => setSelectedTanks(prev => ({ ...prev, [tank]: !prev[tank] }));

    if (isLoading) return <Typography>Loading...</Typography>;
    if (isError) return <Typography>Error fetching ATG reports</Typography>;

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography style={{ marginBottom: '10px' }}>Select Time Range:</Typography>
                    <Select value={range} onChange={e => setRange(e.target.value)} style={{ marginBottom: '10px', alignSelf: 'center' }}>
                        <MenuItem value="day">Past Day</MenuItem>
                        <MenuItem value="week">Past Week</MenuItem>
                        <MenuItem value="month">Past Month</MenuItem>
                        <MenuItem value="year">Past Year</MenuItem>
                    </Select>
                    <button
                        onClick={() => generatePDF(data, range, atgAttributes, logoImg, config)}
                        style={{
                            borderColor: 'darkgrey',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            backgroundColor: 'transparent',
                            color: 'darkgrey',
                            padding: '10px 20px',
                            borderRadius: '4px',
                            alignSelf: 'center'
                        }}
                    >
                        Download PDF Report
                    </button>
                </div>
                { sortedReports && (
                <TankAnalytics data={sortedReports} range={range} />)
                }
            </div>
            <div>
                <Tabs value={selectedTab} onChange={handleTabChange} indicatorColor="primary" textColor="secondary">
                    <Tab label="Charts" />
                    <Tab label="Data Grids" />
                </Tabs>
                {selectedTab === 0 && sortedReports && selectedTanks && (
                    <ChartTab selectedTanks={selectedTanks} handleTankCheck={handleTankCheck} data={sortedReports} range={range} config={config}/>
                )}
                {selectedTab === 1 && <DataGridTab dataForGrids={dataForGrids} columns={columns} />}
            </div>
        </div>
    );
}

export default InventoryHistory;
