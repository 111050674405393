import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from './authSlice';
import { useLoginMutation } from './authApiSlice';
import usePersist from '../../hooks/usePersist';
import useTitle from '../../hooks/useTitle';
import PulseLoader from 'react-spinners/PulseLoader';
import { Box, Button, FormControl, InputLabel, Input, Paper, Typography, Checkbox, FormControlLabel, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { usfuel } from '../../assets';
import { motion } from 'framer-motion';
import { useSendResetEmailMutation } from '../users/usersApiSlice';


const Login = () => {
    useTitle('Employee Login');
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const userRef = useRef();
    const errRef = useRef();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [persist, setPersist] = usePersist();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [login, { isLoading }] = useLoginMutation();
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [emailForReset, setEmailForReset] = useState('');
    const [sendResetEmail] = useSendResetEmailMutation();

    useEffect(() => {
        userRef.current.focus();
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [username, password]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { accessToken, isFirstLogin } = await login({ username, password }).unwrap();
            dispatch(setCredentials({ accessToken }));
            setUsername('');
            setPassword('');
            if (isFirstLogin) {
                navigate('/reset-password');
            } else {
                navigate('/console');
            }
        } catch (err) {
            if (!err.status) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg(err.data?.message);
            }
            errRef.current.focus();
        }
    };

    const handleUserInput = (e) => setUsername(e.target.value);
    const handlePwdInput = (e) => setPassword(e.target.value);
    const handleToggle = () => setPersist((prev) => !prev);

    const handleSendResetEmail = async () => {
        try {
            await sendResetEmail(emailForReset).unwrap();
            alert("Reset email sent successfully. Please check your inbox.");
        } catch (error) {
            alert("Failed to send reset email.");
        }
    };

    if (isLoading) return <PulseLoader color={'#000'} />;

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: colors.grey[600],
            }}
        >
            <motion.div
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.6 }}
            >
                <Paper
                    elevation={6}
                    sx={{
                        p: 6,
                        width: '100%',
                        maxWidth: '400px',
                        textAlign: 'center',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 1)',
                        backgroundColor: colors.grey[200],
                        borderRadius: '20px',
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <img src={usfuel} alt="Logo" style={{ width: '200px' }} />
                    </Box>
                    <Typography
                        variant="h2"
                        color={colors.blueAccent[700]}
                        gutterBottom
                        sx={{ fontFamily: theme.typography.fontFamily }} // Explicitly set the font from the theme
                    >
                        Login
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} id="login-form">
                        <FormControl fullWidth sx={{ mb: 2 }}>
                            <InputLabel
                                htmlFor="username"
                                sx={{
                                    fontSize: '18px',
                                    color: colors.primary[600],
                                }}
                            >
                                Username
                            </InputLabel>
                            <Input
                                id="username"
                                type="text"
                                value={username}
                                onChange={handleUserInput}
                                autoComplete="off"
                                required
                                inputRef={userRef}
                            />
                        </FormControl>
                        <FormControl fullWidth sx={{ mb: 3 }}>
                            <InputLabel
                                htmlFor="password"
                                sx={{
                                    fontSize: '18px',
                                    color: colors.primary[600],
                                }}
                            >
                                Password
                            </InputLabel>
                            <Input
                                id="password"
                                type="password"
                                value={password}
                                onChange={handlePwdInput}
                                required
                            />
                        </FormControl>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={persist}
                                        onChange={handleToggle}
                                        color="primary"
                                    />
                                }
                                label="Trust This Device"
                            />
                        </Box>
                        <Typography variant="body2" color="error" ref={errRef} sx={{ mb: 2 }}>
                            {errMsg}
                        </Typography>
                        <motion.div
                            whileTap={{ scale: 0.95 }}
                            onClick={() => {
                                const form = document.getElementById('login-form');
                                form.style.opacity = 0;
                                form.style.transform = 'scale(0.8)';
                                setTimeout(() => navigate('/console'), 600);
                            }}
                        >
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                sx={{
                                    mt: 2,
                                    fontFamily: 'Jura, sans-serif',
                                    fontSize: '16px',
                                    backgroundColor: colors.primary[500],
                                    '&:hover': {
                                        backgroundColor: colors.primary[700],
                                    }
                                }}
                            >
                                Sign In
                            </Button>
                        </motion.div>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        <Link to="/" style={{ fontFamily: 'Jura, sans-serif', textDecoration: 'none', color: colors.blueAccent[700] }}>
                            Back to Home
                        </Link>
                    </Box>
                    <Box sx={{ mt: 3 }}>
                        {showForgotPassword ? (
                            <>
                                <FormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel
                                        htmlFor="emailForReset"
                                        sx={{
                                            fontSize: '18px',
                                            color: colors.primary[600],
                                        }}
                                    >
                                        Email
                                    </InputLabel>
                                    <Input
                                        id="emailForReset"
                                        type="email"
                                        value={emailForReset}
                                        onChange={(e) => setEmailForReset(e.target.value)}
                                        required
                                    />
                                </FormControl>
                                <Button fullWidth variant="contained" color="primary" onClick={handleSendResetEmail}>
                                    Send Reset Email
                                </Button>
                                <Button fullWidth variant="outlined" color="primary" onClick={() => setShowForgotPassword(false)}>
                                    Cancel
                                </Button>
                            </>
                        ) : (
                            <Button fullWidth variant="text" onClick={() => setShowForgotPassword(true)}>
                                Forgot Password?
                            </Button>
                        )}
                    </Box>
                </Paper>
            </motion.div>
        </Box>
    );
};

export default Login;
