import React, { useState } from 'react';
import {
    Grid,
    Paper,
    useTheme,
    Box,
    Typography,
    Collapse,
    IconButton
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

import { tokens } from '../../../../theme';
import NotificationSettings from './settings_components/NotificationSettings';
import ShareReporting from './settings_components/ShareReporting';
import JobsDispatch from './settings_components/JobsDispatch';
import Header from '../../global/Header';

const AtgSettingsTab = ({ atg, atg_name }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // State for controlling the collapse of each section
    const [alertsOpen, setAlertsOpen] = useState(true);
    const [controlsOpen, setControlsOpen] = useState(true);

    return (
        <Paper
            elevation={3}
            style={{
                padding: '20px',
                background: `linear-gradient(to bottom, ${colors.primary[400]}, ${colors.primary[600]})`
            }}
        >
            <Header
                title="ATG Settings and Controls"
                subtitle="Manage ATG notification and alert settings, as well as remotely control the device"
            />

            {/* ATG Alerts & Notifications Section */}
            <Box mt={3}>
                <Paper
                    elevation={1}
                    style={{
                        padding: '15px',
                        background: `linear-gradient(to bottom, ${colors.primary[500]}, ${colors.primary[700]})`
                    }}
                >
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography 
                            variant="h4" 
                            gutterBottom 
                            style={{ fontWeight: alertsOpen ? 'normal' : 'bold' }}
                        >
                            ATG Alerts & Notifications
                        </Typography>
                        <IconButton onClick={() => setAlertsOpen(!alertsOpen)}>
                            {alertsOpen ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </Box>
                    <Collapse in={alertsOpen}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <NotificationSettings atg={atg} atg_name={atg_name} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ShareReporting atg={atg} atg_name={atg_name} />
                            </Grid>
                        </Grid>
                    </Collapse>
                </Paper>
            </Box>

            {/* ATG Controls Section */}
            <Box mt={3}>
                <Paper
                    elevation={1}
                    style={{
                        padding: '15px',
                        background: `linear-gradient(to bottom, ${colors.primary[500]}, ${colors.greenAccent[800]})`
                    }}
                >
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography 
                            variant="h4" 
                            gutterBottom 
                            style={{ fontWeight: controlsOpen ? 'normal' : 'bold' }}
                        >
                            ATG Controls
                        </Typography>
                        <IconButton onClick={() => setControlsOpen(!controlsOpen)}>
                            {controlsOpen ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </Box>
                    <Collapse in={controlsOpen}>
                        <JobsDispatch atg={atg} atg_name={atg_name} />
                    </Collapse>
                </Paper>
            </Box>
        </Paper>
    );
};

export default AtgSettingsTab;
