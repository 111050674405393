import React from 'react';
import { Typography, Card, CardContent, Paper } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { tokens } from '../../../../../theme';

// Utility function for formatting date ranges
const formatDateRange = (startDate, endDate) => {
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const start = new Date(startDate).toLocaleDateString('en-US', options);
    const endDay = new Date(endDate).getDate();
    const endMonth = new Date(endDate).toLocaleString('en-US', { month: 'long' });

    // Display month only once if start and end month are the same
    return start.includes(endMonth)
        ? `${start.split(" ")[0]} ${start.split(" ")[1]}-${endDay}, ${start.split(" ")[2]}`
        : `${start} - ${new Date(endDate).toLocaleDateString('en-US', options)}`;
};


const TankAnalytics = ({ data, range }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // Initialize metrics
    const metrics = {
        tank_1: { totalLost: 0, average: 0 },
        tank_2: { totalLost: 0, average: 0 },
        tank_3: { totalLost: 0, average: 0 },
        tank_4: { totalLost: 0, average: 0 }
    };

    // Get start and end times
    const startTime = data.length ? data[0].state.reported.system_time : 'N/A';
    const startDate = data.length ? data[0].state.reported.system_date : 'N/A';
    const endTime = data.length ? data[data.length - 1].state.reported.system_time : 'N/A';
    const endDate = data.length ? data[data.length - 1].state.reported.system_date : 'N/A';

    // Calculate metrics
    data.forEach((report, index) => {
        if (!report || typeof report !== 'object' || !report.state || !report.state.reported) return; // Skip if data is missing

        Object.keys(metrics).forEach(tankKey => {
            const currentTankData = report.state.reported.tanks[tankKey]?.tank_data?.[0];
            const previousTankData = data[index - 1]?.state?.reported?.tanks[tankKey]?.tank_data?.[0];

            if (currentTankData && previousTankData) {
                const lost = previousTankData.volume - currentTankData.volume;
                metrics[tankKey].totalLost += lost < 0 ? 0 : lost;
            }

            if (currentTankData) {
                metrics[tankKey].average += currentTankData.volume;
            }
        });
    });

    // Finalize average calculations
    Object.keys(metrics).forEach(tankKey => {
        if (data.length > 0) {
            metrics[tankKey].average /= data.length;
        }
    });

    const formatNumber = (num) => {
        return Math.round(num).toLocaleString() + " (Gal.)";
    };

    return (
        <Card style={{ backgroundColor: colors.neutral, width: 'fit-content', margin: '0 auto' }}>
            <CardContent>
                <Typography variant="h3" style={{ color: colors.primary.main }}>
                    {`Analytics for ${range} - ${formatDateRange(startDate, endDate)}`}
                </Typography>

                <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', gap: '10px' }}>
                    {Object.keys(metrics).map(tankKey => (
                        metrics[tankKey].totalLost > 0 && (
                            <Paper key={tankKey} elevation={3} style={{
                                borderRadius: '8px',
                                margin: '5px',
                                padding: '15px',
                                backgroundColor: colors.primary[100],
                                textAlign: 'center'
                            }}>
                                <Typography variant="h4" style={{ color: colors.primary[900], marginBottom: '10px' }}>{`Tank ${tankKey.split('_')[1]}`}</Typography>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                                    <Typography variant="h4" style={{ color: colors.grey[600] }}>Total Volume Lost:</Typography>
                                    <Typography variant="h3" style={{ color: colors.blueAccent[600] }}>{formatNumber(metrics[tankKey].totalLost)}</Typography>
                                </div>
                                <Typography variant="h4" style={{ color: colors.grey[700] }}>{`Average Volume: ${formatNumber(metrics[tankKey].average)}`}</Typography>
                            </Paper>
                        )
                    ))}
                </div>
            </CardContent>
        </Card>
    );
};

export default TankAnalytics;
