import React, { useState } from 'react';
import { useTheme, Paper, Typography, Box, Collapse, IconButton } from "@mui/material";
import { CheckCircle, ErrorOutline, ExpandMore, ExpandLess } from '@mui/icons-material';
import { useGetATGLeakDataQuery, useGetDistinctCSLDHistoryQuery } from '../../../../../features/atgs/atgsApiSlice'; // New CSLD history query import
import { tokens } from '../../../../../theme';

const USFuelCSLD = ({ atg_name, config }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [currentDataOpen, setCurrentDataOpen] = useState(true);
    const [historyOpen, setHistoryOpen] = useState(false);

    const { data: leakData, isLoading: isLeakLoading } = useGetATGLeakDataQuery(atg_name);
    const { data: historyData, isLoading: isHistoryLoading } = useGetDistinctCSLDHistoryQuery(atg_name);
    const tanks = leakData?.shadow?.tanks || {};

    // Parse fuel types from config
    const fuelTypes = Object.keys(config.tank_config || {}).reduce((acc, tankKey) => {
        acc[tankKey] = config.tank_config[tankKey].product_label;
        return acc;
    }, {});

    return (
        <Box>
            {/* Current CSLD Data Section */}
            <Paper
                elevation={3}
                style={{
                    padding: '20px',
                    background: `linear-gradient(to bottom, ${colors.primary[400]}, ${colors.primary[600]})`,
                    marginBottom: '20px'
                }}
            >
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" style={{ color: colors.greenAccent[300] }}>
                        Current CSLD Data
                    </Typography>
                    <IconButton onClick={() => setCurrentDataOpen(!currentDataOpen)}>
                        {currentDataOpen ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </Box>
                {!isLeakLoading && (<Collapse in={currentDataOpen}>
                    <Box display="flex" flexWrap="wrap" gap={2} mt={2}>
                        {Object.entries(tanks).map(([tankId, tankData]) => {
                            const csldResult = tankData?.usfuel_csld_result;
                            const fuelType = fuelTypes[tankId] || `Tank ${tankId}`;
                            if (csldResult && (csldResult.status === "Running" || csldResult.status === "Stopped")) {
                                const isLeaking = csldResult.average_leak_rate > 0.1;
                                return (
                                    <TankStatusCard
                                        key={tankId}
                                        tankId={fuelType} // Use fuel type label here
                                        csldResult={{ ...csldResult, isLeaking }}
                                    />
                                );
                            }
                            return null;
                        })}
                    </Box>
                </Collapse>)}
            </Paper>

            {/* CSLD History Section */}
            <Paper
                elevation={3}
                style={{
                    padding: '20px',
                    background: `linear-gradient(to bottom, ${colors.blueAccent[600]}, ${colors.primary[600]})`
                }}
            >
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" style={{ color: colors.greenAccent[300] }}>
                        CSLD History
                    </Typography>
                    <IconButton onClick={() => setHistoryOpen(!historyOpen)}>
                        {historyOpen ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </Box>
                {!isHistoryLoading && (<Collapse in={historyOpen}>
                    <Box mt={2}>
                        {historyData && Object.keys(historyData).length > 0 ? (
                            Object.entries(historyData).map(([tankId, history]) => {
                                const fuelType = fuelTypes[tankId] || `Tank ${tankId}`;
                                return (
                                    <CSLDHistoryCard
                                        key={tankId}
                                        tankId={fuelType} // Use fuel type label here
                                        history={history}
                                    />
                                );
                            })
                        ) : (
                            <Typography variant="body2">No CSLD history data available for any tanks.</Typography>
                        )}
                    </Box>
                </Collapse>)}
            </Paper>
        </Box>
    );
};

const TankStatusCard = ({ tankId, csldResult }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Paper elevation={3} style={{
            padding: '15px',
            marginBottom: '15px',
            background: colors.primary[500],
            color: colors.primary[50]
        }}>
            <Box display="flex" alignItems="center" gap={2} mb={2}>
                {csldResult.isLeaking ? <ErrorOutline style={{ color: 'red' }} /> : <CheckCircle style={{ color: 'green' }} />}
                <Typography variant="h6" fontWeight="bold">
                    {tankId} - {csldResult.isLeaking ? "Leaking" : "Stable"}
                </Typography>
            </Box>
            <Typography variant="body1">Test Status: {csldResult.status} </Typography>
            <Typography variant="body1">Average Leak Rate: {csldResult.average_leak_rate.toFixed(4)} gal/hr</Typography>
            <Typography variant="body1">Lowest Leak Rate: {csldResult.lowest_leak_rate.toFixed(4)} gal/hr</Typography>
            <Typography variant="body1">Total Volume Decreased: {csldResult.total_volume_decreased.toFixed(2)} gal</Typography>
            <Typography variant="body1">Test Time Elapsed: {csldResult.current_test_time_elapsed}</Typography>
            <Typography variant="body1">Last Updated: {csldResult.timestamp}</Typography>
        </Paper>
    );
};

const CSLDHistoryCard = ({ tankId, history }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [expanded, setExpanded] = useState(false);

    return (
        <Paper elevation={3} style={{ padding: '15px', marginBottom: '15px', background: colors.primary[500], color: colors.primary[50] }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h6">{tankId} - Historical CSLD Data</Typography>
                <IconButton onClick={() => setExpanded(!expanded)}>
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </Box>
            <Collapse in={expanded}>
                {history && history.length > 0 ? (
                    history.map((csldResult, index) => {
                        const isPass = csldResult.average_leak_rate <= 0.1;
                        return (
                            <Box key={index} mt={2} borderTop={`1px solid ${colors.primary[300]}`} pt={2}>
                                <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                                    <Typography variant="h6" fontWeight="bold">
                                        Test at {csldResult.csld_test_start_time || "N/A"} - {tankId}
                                    </Typography>
                                    <Box display="flex" alignItems="center" gap={1}>
                                        <Typography variant="h5" fontWeight="bold" style={{ color: isPass ? 'green' : 'red' }}>
                                            {isPass ? "Passed" : "Failed"}
                                        </Typography>
                                        {isPass ? (
                                            <CheckCircle style={{ color: 'green', fontSize: '1.5rem' }} />
                                        ) : (
                                            <ErrorOutline style={{ color: 'red', fontSize: '1.5rem' }} />
                                        )}
                                    </Box>

                                </Box>
                                <Box>
                                    <Typography variant="body1"><strong>Test Start:</strong> {csldResult.csld_test_start_time || "N/A"}</Typography>
                                    <Typography variant="body1"><strong>Test End:</strong> {csldResult.csld_test_end_time || "N/A"}</Typography>
                                    <Typography variant="body1"><strong>Average Leak Rate:</strong> {(csldResult.average_leak_rate || 0).toFixed(4)} gal/hr</Typography>
                                    <Typography variant="body1"><strong>Lowest Leak Rate:</strong> {(csldResult.lowest_leak_rate || 0).toFixed(4)} gal/hr</Typography>
                                    <Typography variant="body1"><strong>Total Volume Decreased:</strong> {(csldResult.total_volume_decreased || 0).toFixed(2)} gal</Typography>
                                    <Typography variant="body1"><strong>Data Points Collected:</strong> {csldResult.data_points_collected || 0}</Typography>
                                    <Typography variant="body1">
                                        <strong>Summary:</strong> Min: {(csldResult.filtered_rates_summary?.min || 0).toFixed(2)}, Max: {(csldResult.filtered_rates_summary?.max || 0).toFixed(2)}, Avg: {(csldResult.filtered_rates_summary?.average || 0).toFixed(4)}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    })
                ) : (
                    <Typography variant="body2">No historical CSLD data available for this tank.</Typography>
                )}
            </Collapse>
        </Paper>
    );
};


export default USFuelCSLD;
