import { Box, Icon, IconButton, useTheme } from '@mui/material';
import { useContext } from 'react';
import { ColorModeContext, tokens } from '../../../theme';
import InputBase from '@mui/material/InputBase';
import { LightModeOutlined, NotificationsOutlined, DarkModeOutlined, SettingsOutlined, PersonOutlined, SearchOutlined } from '@mui/icons-material';
import useAuth from '../../../hooks/useAuth';
import { Link } from 'react-router-dom';

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { username, isManager, isAdmin } = useAuth();  // Get username from useAuth

  return (
  <Box display="flex" marginRight="auto" justifyContent="space-between" p={4}>
      

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlined />
          ) : (
            <LightModeOutlined />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlined />
        </IconButton>
        <IconButton>
          <SettingsOutlined />
        </IconButton>
        <Link to={`/console/users/${username}`}>  {/* Wrap the IconButton with Link */}
          <IconButton>
            <PersonOutlined />
          </IconButton>
        </Link>
      </Box>
  </Box>
  );
};

export default Topbar;