// ReportsTab.jsx
import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper, useTheme } from "@mui/material";
import InventoryHistory from './components/InventoryHistory';
import { tokens } from '../../../../theme';
import { useGetAtgQuery } from '../../../../features/atgs/atgsApiSlice';
import { usfuelblack } from '../../../../assets';
import USFuelCSLD from './components/USFuelCSLD';

const ReportsTab = ({ atg, atg_name, config }) => {
    const [selectedSection, setSelectedSection] = useState('InventoryHistory');

    const {
        data: atgReturn,
        isAtgLoading,
        isAtgSuccess,
        isAtgError,
        Atgerror
    } = useGetAtgQuery(atg_name, {
        pollingInterval: 30000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const atgAttributes = atgReturn?.attributes;

    const [logoImg, setLogoImg] = useState(null);

    // Fetch the logo PNG
    useEffect(() => {
        fetch(usfuelblack)
            .then((res) => res.blob())
            .then((blob) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    const base64data = reader.result;
                    setLogoImg(base64data);
                }
            });
    }, []);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box display="flex" height="100%" width="100%">
            {/* Sidebar */}
            <Paper
                elevation={2}
                style={{
                    width: '250px', // Fixed width for the sidebar
                    height: '100vh', // Occupies full viewport height
                    padding: '20px',
                    position: 'sticky',
                    top: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: colors.primary[700],
                    alignItems: 'flex-start',
                }}
            >
                <Typography variant="h3" style={{ color: colors.primary[100], fontWeight: 'bold' }} gutterBottom>Reports:</Typography>
                <Typography
                    variant="h4"
                    style={{ cursor: 'pointer', color: selectedSection === 'InventoryHistory' ? colors.greenAccent[400] : colors.primary[200] }}
                    onClick={() => setSelectedSection('InventoryHistory')}
                >
                    Inventory
                </Typography>
                <Typography
                    variant="h4"
                    style={{ cursor: 'pointer', color: selectedSection === 'USFuelCSLD' ? colors.greenAccent[400] : colors.primary[200] }}
                    onClick={() => setSelectedSection('USFuelCSLD')}
                >
                    USFuel CSLD
                </Typography>
            </Paper>

            {/* Main Content */}
            <Box flex="1" padding="20px" overflow="auto">
                {selectedSection === 'InventoryHistory' && <InventoryHistory atg_name={atg_name} atgAttributes={atgAttributes} atg={atg} config={config} logoImg={logoImg}/>}
                {selectedSection === 'USFuelCSLD' && <USFuelCSLD atg_name={atg_name} config={config}/>}
            </Box>
        </Box>
    );
};

export default ReportsTab;
