import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { useTheme, Typography, FormControlLabel, Checkbox, Paper } from "@mui/material";
import { styled } from '@mui/system';

import { tokens } from '../../../../../theme';

const domainRange = {
    day: [new Date(Date.now() - 24 * 60 * 60 * 1000).getTime(), Date.now()],
    week: [new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).getTime(), Date.now()],
    month: [new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).getTime(), Date.now()],
    year: [new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).getTime(), Date.now()]
};

const ChartContainer = styled('div')({
    width: '100%',
    height: '400px',
});

const tankColors = {
    tank_1: { volume: '#8884d8', ullage: '#4848d8', temp: '#d88484', height: '#d8a484' },
    tank_2: { volume: '#82ca9d', ullage: '#42aa7d', temp: '#ca8282', height: '#caa482' },
    tank_3: { volume: '#ffc658', ullage: '#ff9e28', temp: '#c85858', height: '#c8a858' },
    tank_4: { volume: '#e04141', ullage: '#a03131', temp: '#41e0e0', height: '#41e041' }
};

const hasData = (data, tank) => {
    return data.some(point => point.state?.reported?.tanks[tank]?.tank_data?.[0]?.volume);
};

const ChartTab = ({ selectedTanks, handleTankCheck, data, range }) => {
    console.log("Selected tanks:", selectedTanks)
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const StyledPaper = styled(Paper)(({ theme, colors }) => ({
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        background: `linear-gradient(135deg, ${colors.primary[200]} 0%, ${colors.primary[400]} 100%)`,
        borderRadius: '15px',
    }));


    return (
        <div>
            <Typography variant="h3">Volume Vs. Ullage</Typography>
            <div>
                {Object.keys(tankColors).map(tank => ( // Only iterate over valid tank keys
                    hasData(data, tank) && (
                        <FormControlLabel
                            key={tank}
                            control={
                                <Checkbox
                                    checked={selectedTanks[tank]}
                                    onChange={() => handleTankCheck(tank)}
                                    name={tank}
                                    color="primary"
                                    style={{
                                        backgroundColor: selectedTanks[tank] ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                                        color: selectedTanks[tank] ? '#ffffff' : '#000000',
                                    }}
                                />
                            }
                            label={
                                <>
                                    <span style={{ color: tankColors[tank].volume, marginRight: '5px' }}>●</span>
                                    <span style={{ color: tankColors[tank].ullage, marginRight: '5px' }}>●</span>
                                    {`Tank ${tank.split('_')[1]} (V, U)`}
                                </>
                            }
                        />
                    )
                ))}
            </div>
            <StyledPaper theme={theme} colors={colors}>
                <ChartContainer>
                    <ResponsiveContainer>
                        <LineChart data={data}>
                            <CartesianGrid strokeDasharray="4 3" />
                            <XAxis
                                dataKey={item => new Date(`${item.state.reported.system_date} ${item.state.reported.system_time}`).getTime()}
                                tickFormatter={(value) => {
                                    const date = new Date(value);
                                    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
                                }}
                                domain={[domainRange[range][0], domainRange[range][1]]}
                                type="number"
                                angle={-45}
                                height={80}
                                interval={range === 'week' ? 4 : range === 'month' ? 24 : range === 'year' ? 124 : 0}
                                scale="time"
                                tick={{ fill: 'white' }}
                            />
                            <YAxis
                                yAxisId="left"
                                orientation="left"
                                stroke={colors.primary[600]}
                                tick={{ fill: colors.primary[700], fontSize: '0.875rem' }} // Sets the color and size of tick labels
                                label={{
                                    value: 'Volume (Gallons)',
                                    angle: -90,
                                    position: 'insideLeft',
                                    style: { fill: colors.primary[700], fontSize: '1.2rem' } // Sets color and size of the axis labelinsideRight
                                }}
                            />

                            <YAxis yAxisId="right" orientation="right" stroke={colors.primary[100]}
                                tick={{ fill: colors.primary[200], fontSize: '0.875rem' }} // Sets the color and size of tick labels
                                label={{
                                    value: 'Ullage (Gallons)',
                                    angle: -90,
                                    position: 'insideRight',
                                    style: { fill: colors.primary[100], fontSize: '1.2rem' } // Sets color and size of the axis labelinsideRight
                                }}
                            />
                            <Tooltip />
                            {Object.keys(selectedTanks).map(tank => (
                                selectedTanks[tank] && tankColors[tank] && ( // Ensure tankColors[tank] exists
                                    <React.Fragment key={tank}>
                                        <Line key={`${tank}-volume`} yAxisId="left" type="monotone" dataKey={item => item.state.reported.tanks[tank]?.tank_data?.[0]?.volume} stroke={tankColors[tank].volume} strokeWidth={3} />
                                        <Line key={`${tank}-ullage`} yAxisId="right" type="monotone" dataKey={item => item.state.reported.tanks[tank]?.tank_data?.[0]?.ullage} stroke={tankColors[tank].ullage} strokeWidth={3} />
                                    </React.Fragment>
                                )
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                </ChartContainer>
            </StyledPaper>
            <Typography variant="h3">Temperature Vs. Height</Typography>
            <StyledPaper theme={theme} colors={colors}>
                <ChartContainer>
                    <ResponsiveContainer>
                        <LineChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey={item => new Date(`${item.state.reported.system_date} ${item.state.reported.system_time}`).getTime()}
                                tickFormatter={(value) => {
                                    const date = new Date(value);
                                    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
                                }}
                                domain={[domainRange[range][0], domainRange[range][1]]}
                                type="number"
                                angle={-45}
                                height={80}
                                interval={range === 'week' ? 4 : range === 'month' ? 24 : range === 'year' ? 99 : 0}
                                scale="time"
                                tick={{ fill: 'white' }}
                            />
                            <YAxis
                                yAxisId="left"
                                orientation="left"
                                stroke="#d88484"
                                tick={{ fill: colors.primary[700], fontSize: '0.875rem' }} // Sets the color and size of tick labels
                                label={{
                                    value: 'Temperature (°F)',
                                    angle: -90,
                                    position: 'insideLeft',
                                    style: { fill: colors.primary[700], fontSize: '1.2rem' } // Sets color and size of the axis label
                                }}
                            />
                            <YAxis
                                yAxisId="right"
                                orientation="right"
                                stroke="#d8a484"
                                tick={{ fill: colors.primary[200], fontSize: '0.875rem' }} // Sets the color and size of tick labels
                                label={{
                                    value: 'Height (inches)',
                                    angle: 90,
                                    position: 'insideRight',
                                    style: { fill: colors.primary[100], fontSize: '1.2rem' } // Sets color and size of the axis label
                                }}
                            />

                            <Tooltip />
                            {Object.keys(selectedTanks).map(tank => (
                                selectedTanks[tank] && tankColors[tank] && ( // Ensure tankColors[tank] exists
                                    <React.Fragment key={tank}>
                                        <Line key={`${tank}-temp`} yAxisId="left" type="monotone" dataKey={item => item.state.reported.tanks[tank]?.tank_data?.[0]?.temperature} stroke={tankColors[tank].temp} strokeWidth={3} />
                                        <Line key={`${tank}-height`} yAxisId="right" type="monotone" dataKey={item => item.state.reported.tanks[tank]?.tank_data?.[0]?.height} stroke={tankColors[tank].height} strokeWidth={3} />
                                    </React.Fragment>
                                )
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                </ChartContainer>
            </StyledPaper>

        </div>
    );
};

export default ChartTab;
