import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Public from './components/Public'
import Login from './features/auth/Login'
import Welcome from './features/auth/Welcome'
import RequireAuth from './features/auth/RequireAuth'
import UsersList from './features/users/UsersList'
import Console from './features/auth/Console'
import Features from './components/Features'
import Contact from './components/Contact'
import { ThemeProvider } from '@mui/material/styles';
import ResetPassword from './components/react-dashboard/user_actions/ResetPassword'
import PersistLogin from './features/auth/PersistLogin'
import Store from './components/Store'
import ItemDetails from './components/react-public/store-components/ItemDetails'
import Checkout from './components/react-public/Checkout'
import CommanderLogin from './components/react-dashboard/commander/CommanderLogin'
import CommanderResetPassword from './components/react-dashboard/commander/CommanderResetPassword'
import CommanderConsole from './components/react-dashboard/commander/CommanderConsole'

import { useEffect } from 'react'

import { useMode, ColorModeContext } from './theme';

function App() {
  const [theme, colorMode] = useMode();

  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }, []);

  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;700&family=Jura:wght@300..700&display=swap';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }, []);



  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* public routes */}
            <Route index element={<Public />} />
            <Route path="features" element={<Features />} />
            <Route path="contact" element={<Contact />} />
            <Route path="login" element={<Login />} />
            <Route path="store" element={<Store />} />
            <Route path="checkout" element={<Checkout />} />
            <Route path="item/:itemId" element={<ItemDetails />} />
            <Route path="reset-password/:token" element={<ResetPassword />} />

            <Route path="commander" element={<CommanderLogin />} />
            <Route path="commander-reset-password" element={<CommanderResetPassword />} />
            
            {/* protected commander routes */}
            <Route element={<RequireAuth isCommander={true} />}>
              <Route path="commander-console" element={<CommanderConsole />} />
            </Route>

            {/* protected routes */}
            <Route element={<PersistLogin />}>
              <Route element={<RequireAuth isCommander={false} />}>
                <Route path="welcome" element={<Welcome />} />
                <Route path="console/*" element={<Console />} />
                <Route path="userslist" element={<UsersList />} />
                <Route path="reset-password" element={<ResetPassword />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider >
  );
}

export default App;
