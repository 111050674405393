import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useResetCommanderPasswordMutation } from '../../../features/commander/commanderApiSlice';
import { Box, Button, FormControl, InputLabel, Input, Paper, Typography, IconButton, useTheme } from '@mui/material';
import { Visibility, VisibilityOff, CheckCircle, Cancel } from '@mui/icons-material';
import { usfuel } from '../../../assets';
import { tokens } from '../../../theme';

const CommanderResetPassword = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const location = useLocation();
    const navigate = useNavigate();

    // Get session and username from navigation state (passed from login)
    const { session, username } = location.state || {};

    const [newPassword, setNewPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const [resetCommanderPassword, { isLoading }] = useResetCommanderPasswordMutation();

    // Define password criteria
    const passwordCriteria = {
        length: newPassword.length >= 8,
        lowercase: /[a-z]/.test(newPassword),
        uppercase: /[A-Z]/.test(newPassword),
        number: /\d/.test(newPassword),
        specialChar: /[@$!%*?&]/.test(newPassword),
    };

    const allCriteriaMet = Object.values(passwordCriteria).every(Boolean);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Ensure all criteria are met
        if (!allCriteriaMet) {
            setErrMsg('Password must meet all requirements.');
            return;
        }

        try {
            // Send reset password request with the session from the login page
            const response = await resetCommanderPassword({
                username,
                newPassword,
                session,  // Use the session passed from the login page
            }).unwrap();

            console.log('Reset Password Response:', response); // Log successful response
            navigate('/commander-console'); // Redirect to Commander Console after password reset
        } catch (err) {
            console.error('Reset Password Failed:', err); // Log the error for debugging
            setErrMsg('Failed to reset password');
        }
    };

    // Define styles for the checkmarks and error icons
    const checkIconStyles = (criteriaMet) => ({
        color: criteriaMet ? '#00e676' : '#ff1744',  // Green if met, red if not
        backgroundColor: criteriaMet ? '#00e676' : '#ff1744',
        borderRadius: '50%',
        padding: '4px',
    });

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: colors.primary[500],
            }}
        >
            <Paper
                elevation={6}
                sx={{
                    p: 6,
                    width: '100%',
                    maxWidth: '400px',
                    textAlign: 'center',
                    borderRadius: '20px',
                }}
            >
                {/* Logo */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <img src={usfuel} alt="Logo" style={{ width: '200px' }} />
                </Box>

                {/* Title for ATG Commander Software */}
                <Typography variant="h5" sx={{ color: '#b0b0b0', mb: 2 }}>
                    ATG Commander Software
                </Typography>

                <Typography variant="h4" sx={{ mb: 2 }}>Set Your New Password</Typography>


                {/* Password Criteria Paper */}
                <Paper
                    sx={{
                        mb: 2,
                        p: 2,
                        backgroundColor: colors.primary[300],
                        borderRadius: '10px',
                    }}
                    elevation={3}
                >
                    {/* Each password criteria */}
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        {passwordCriteria.length ? (
                            <CheckCircle style={checkIconStyles(true)} />
                        ) : (
                            <Cancel style={checkIconStyles(false)} />
                        )}
                        <Typography sx={{ ml: 1 }}>At least 8 characters</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        {passwordCriteria.lowercase ? (
                            <CheckCircle style={checkIconStyles(true)} />
                        ) : (
                            <Cancel style={checkIconStyles(false)} />
                        )}
                        <Typography sx={{ ml: 1 }}>At least 1 lowercase letter</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        {passwordCriteria.uppercase ? (
                            <CheckCircle style={checkIconStyles(true)} />
                        ) : (
                            <Cancel style={checkIconStyles(false)} />
                        )}
                        <Typography sx={{ ml: 1 }}>At least 1 uppercase letter</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        {passwordCriteria.number ? (
                            <CheckCircle style={checkIconStyles(true)} />
                        ) : (
                            <Cancel style={checkIconStyles(false)} />
                        )}
                        <Typography sx={{ ml: 1 }}>At least 1 number</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        {passwordCriteria.specialChar ? (
                            <CheckCircle style={checkIconStyles(true)} />
                        ) : (
                            <Cancel style={checkIconStyles(false)} />
                        )}
                        <Typography sx={{ ml: 1 }}>At least 1 special character (@, $, !, %, *, ?, &)</Typography>
                    </Box>
                </Paper>

                <Box component="form" onSubmit={handleSubmit}>
                    <FormControl fullWidth sx={{ mb: 2 }}>
                        <InputLabel
                            htmlFor="new-password"
                            sx={{ fontSize: '18px' }}  // Increase font size of the label
                        >
                            New Password
                        </InputLabel>
                        <Input
                            id="new-password"
                            type={showPassword ? 'text' : 'password'}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            sx={{
                                fontSize: '18px',  // Increase font size of the input
                                padding: '12px',   // Add padding for better visibility
                                height: '50px'     // Increase the height of the input field
                            }}
                            endAdornment={
                                <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    aria-label={showPassword ? 'Hide password' : 'Show password'}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            }
                        />
                    </FormControl>


                    {errMsg && <Typography color="error">{errMsg}</Typography>}

                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        disabled={!allCriteriaMet} // Disable button until all criteria are met
                    >
                        Reset Password
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default CommanderResetPassword;
