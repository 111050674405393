import React, { useState } from 'react';
import { Box, Button, Paper, Typography, IconButton, useTheme } from '@mui/material';
import { usfuel } from '../../../assets';
import { Download, VideoLibrary } from '@mui/icons-material';
import { tokens } from '../../../theme';
import { useGetDownloadUrlMutation } from '../../../features/commander/commanderApiSlice';

const CommanderConsole = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [getDownloadUrl] = useGetDownloadUrlMutation();
    const [loading, setLoading] = useState(false);

    const handleDownload = async () => {
        setLoading(true);
        try {
            const response = await getDownloadUrl().unwrap(); // Fetch the download URL
            if (response.downloadUrl) {
                window.open(response.downloadUrl); // Open the download URL
            }
        } catch (error) {
            console.error("Failed to download", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                width: '100vw', // Ensures the width of the entire viewport
                backgroundColor: colors.primary[500],
            }}
        >
            <Paper
                elevation={6}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center', // Vertically centers the content inside the Paper
                    width: '100%',
                    maxWidth: '800px', // Keeps the width of the content manageable
                    p: 4,
                    textAlign: 'center',
                    borderRadius: 2, // Add some rounding to make it look nice
                    backgroundColor: colors.primary[700],
                }}
            >
                {/* Main content: Logo and Download Section */}
                <Box sx={{ width: '60%', pr: 4 }}>
                    {/* Logo */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <img src={usfuel} alt="Logo" style={{ width: '200px' }} />
                    </Box>

                    {/* Title */}
                    <Typography variant="h5" sx={{ color: '#b0b0b0', mb: 2 }}>
                        ATG Commander Software
                    </Typography>

                    {/* Download Button */}
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Download />}
                        sx={{ mt: 2, width: '100%', fontSize: '16px' }}
                        onClick={handleDownload} // Trigger download
                        disabled={loading} // Disable while loading
                    >
                        {loading ? 'Downloading...' : 'Download for Windows'}
                    </Button>

                    {/* New Section: Cable & Driver Information */}
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="body1" sx={{ color: '#f0f0f0' }}>
                            <strong>Important:</strong> To use the ATG Commander software, you will need the correct cable.
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, color: '#b0b0b0' }}>
                            Please ensure that you have a 
                            <strong> USB RS-232 to DB25 Serial Adapter Cable </strong>
                            for Fanuc CNC DNCLINK Configuration Copy Setting, or a similar RS232-to-USB cable with a null modem.
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 2, color: '#b0b0b0' }}>
                            Additionally, you will need to install the 
                            <strong>CP210x USB to UART Bridge</strong> drivers on your Windows machine. 
                            You can download the drivers from 
                            <a href="https://www.silabs.com/developers/usb-to-uart-bridge-vcp-drivers" target="_blank" rel="noopener noreferrer" style={{ color: '#7CFC00' }}>
                                here
                            </a>.
                        </Typography>
                    </Box>
                </Box>

                {/* Info Section */}
                <Box sx={{ width: '35%', pl: 4 }}>
                    {/* Information about the software */}
                    <Typography variant="body1" sx={{ textAlign: 'left', mb: 2 }}>
                        The ATG Commander software is designed to help you quickly pull and flash Veeder-Root ATG configurations.
                        You can easily load configuration files, edit fields, and flash the configuration back to the ATG.
                    </Typography>

                    {/* Placeholder for video tutorial */}
                    <Box sx={{ textAlign: 'center', mt: 3 }}>
                        <IconButton aria-label="Watch tutorial" sx={{ fontSize: '50px', color: '#b0b0b0' }}>
                            <VideoLibrary sx={{ fontSize: '60px' }} />
                        </IconButton>
                        <Typography variant="body2" sx={{ mt: 1, color: '#b0b0b0' }}>
                            Tutorial Video (Coming Soon)
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
};

export default CommanderConsole;
